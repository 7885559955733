@import url('https://fonts.googleapis.com/css2?family=Mukta+Malar:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

body {
  font-family: 'Nunito Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  padding: 0;
  margin: 0;
}

.btn-primary {
    --bs-btn-color: #001414;
    --bs-btn-bg: #0d6efd;
    --bs-btn-border-color: #001414;
    --bs-btn-hover-color: #01F26D;
    --bs-btn-hover-bg: #0b5ed7;
    --bs-btn-hover-border-color: #01F26D;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #01F26D;
    --bs-btn-active-bg: #0a58ca;
    --bs-btn-active-border-color: #01F26D;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #01F26D;
    --bs-btn-disabled-bg: #0d6efd;
    --bs-btn-disabled-border-color: #0d6efd;
}
.form-check-input:checked {
    background-color: #001414;
    border-color: #001414;
}
 
.form-check-input {
    cursor: pointer;
    width: 1.5em;
    height: 1.5em;
}

.form-check .form-check-input {
    border-color: rgb(176, 176, 176);
    box-shadow: none;
    height: 1.5em;
    
}

label.form-check-label {
    font-size: 16px;
    margin: 4px;    
    margin-left: 8px;
}
.p-paginator,
.p-datatable .p-datatable-tbody>tr,
.p-datatable .p-sortable-column.p-highlight,
.p-datatable .p-datatable-thead>tr>th {
    background: white;
}

.p-datatable .p-datatable-header {
    background: white;
}

.p-datatable.p-datatable-sm .p-datatable-header {
    padding: 0.6rem 0.5rem;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.p-datatable .p-datatable-loading-overlay {
    border-radius: 4px;
}

.p-datatable .p-column-header-content {
    padding: 0.2rem;
}

.p-paginator .p-paginator-current {
    border: none;
    height: 1rem;
    padding: 1rem;
}

.p-datatable .p-paginator-bottom {
    max-height: 3.2rem;
    border-width: 0 0 1px 0;
    border-radius: 4px;
}

.p-datatable .p-column-title {
    font-weight: 500;
    padding: 4px 0px;
}

.p-datatable .p-sortable-column:focus {
    box-shadow: none;
}

.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
    border: none;
    min-width: 2rem;
    height: 2rem;
    margin: .143rem;
    border-radius: 50%;
}

.p-datatable.p-datatable-sm .p-datatable-tbody>tr>td {
    padding: 0.7rem;
}

.p-datatable .p-sortable-column.p-highlight {
    color: #01f26d;
}

.p-datatable .p-sortable-column.p-highlight .p-sortable-column-icon {
    color: #01f26d;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover {
    color: #01f26d;
}

.p-datatable .p-sortable-column.p-highlight:not(.p-sortable-disabled):hover .p-sortable-column-icon {
    color: #01f26d;
}

.p-component {
    font-family: 'Nunito Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; 
}